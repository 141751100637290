<script setup lang="ts">
import { DeleteOutlined, EditOutlined } from '@ant-design/icons-vue'
import { h } from 'vue'
import { cloneDeep } from 'lodash-es'

const message = useMessage()
const filesModalOpen = ref(false)

const dbConfigStore = useWorkBenchDBConfigStore()

const { project } = storeToRefs(dbConfigStore)
const localDevice = ref()
const projectRef = ref()
const iconKey = ref()

onMounted(async () => {
  await dbConfigStore.refreshDBConfig()
  localDevice.value = cloneDeep(project.value) ?? { name: '', icon: '', workbenchBackgroundImage: '', loginBackgroundImage: '' }
})

function onChooseIcon(key: string) {
  iconKey.value = key
  filesModalOpen.value = true
}

function onDeleteIcon(key: string) {
  localDevice.value[key] = ''
}

function afterChooseIcon({ accessPath }: any) {
  localDevice.value[iconKey.value] = accessPath
}

function onSubmit() {
  projectRef.value.validate().then(async () => {
    await dbConfigStore.setProject(localDevice.value)
    message.success('修改成功')
  })
}
</script>

<template>
  <page-container>
    <content-header title="项目配置" />
    <flex-content>
      <div style="background: var(--bg-color);" h-full rounded p-12px>
        <a-form v-if="localDevice" ref="projectRef" :model="localDevice" :colon="false" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
          <a-form-item label="项目名称" required>
            <a-input v-model:value="localDevice.name" />
          </a-form-item>
          <a-form-item label="项目图标" required>
            <a-space>
              <a-image v-if="localDevice.icon" :width="30" :height="30" :src="localDevice.icon" :preview="false" />
              <a-button type="link" :icon="h(EditOutlined)" @click="onChooseIcon('icon')" />
              <a-button v-if="localDevice.icon" type="link" danger :icon="h(DeleteOutlined)" @click="onDeleteIcon('icon')" />
            </a-space>
          </a-form-item>
          <a-form-item label="工作台背景图">
            <a-space>
              <a-image v-if="localDevice.workbenchBackgroundImage" :width="30" :height="30" :src="localDevice.workbenchBackgroundImage" :preview="true" />
              <a-button type="link" :icon="h(EditOutlined)" @click="onChooseIcon('workbenchBackgroundImage')" />
              <a-button v-if="localDevice.icon" type="link" danger :icon="h(DeleteOutlined)" @click="onDeleteIcon('workbenchBackgroundImage')" />
            </a-space>
          </a-form-item>
          <a-form-item label="登录页背景图">
            <a-space>
              <a-image v-if="localDevice.loginBackgroundImage" :width="30" :height="30" :src="localDevice.loginBackgroundImage" :preview="true" />
              <a-button type="link" :icon="h(EditOutlined)" @click="onChooseIcon('loginBackgroundImage')" />
              <a-button v-if="localDevice.icon" type="link" danger :icon="h(DeleteOutlined)" @click="onDeleteIcon('loginBackgroundImage')" />
            </a-space>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 14, offset: 3 }">
            <a-button type="primary" @click="onSubmit">
              提交
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </flex-content>

    <FileChoose v-model:open="filesModalOpen" @on-choose-file="afterChooseIcon" />
  </page-container>
</template>
